<script lang="ts">
  import { onMount } from "svelte";
  import Container from "../../components/Container.svelte";
  import Chart from "svelte-frappe-charts";
  import { clear, expiringCourses, filter, init, loading, numCoursesByCountry, numCoursesByType, search, tiles, toggleAllCountries } from "./dashboard.store";
  import Layout from "../../layout/Layout.svelte";
  import { PRIVACY_URL } from "../../utils/constants";
  import { isUserLoggedIn, isUserRoleADM } from "../../app.store";
  import ImageAsset from "../../components/ImageAsset.svelte";
  import Form from "../../components/Form/Form.svelte";
  import FormField from "../../components/Form/FormField.svelte";
  import Switch from "../../components/Form/Fields/Switch.svelte";
  import ClfDropdown from "../../components/Form/Fields/Dropdown/ClfDropdown.svelte";
  import Button from "../../components/Button.svelte";
  import Table from "../../components/Table/Table.svelte";
  import { organizationExpiringCourseDtoFields } from "../../utils/TableFields/organization-expiring-course-dto.fields";

  onMount(() => init());
</script>

<Layout>
  <div class="base">
    <Container>
      <div class="dashboard-container">
        <div class="dashboard-sub-horiz">
          {#if !!$tiles}
            <div class="tile is-ancestor dashboard-tiles noselect">
              <div class="tile is-parent">
                <article class="tile is-child box has-text-centered dashboard-tile">
                  <p class="title is-size-1 dashboard-tile-text">{$tiles.numOrganizations}</p>
                  <p class="subtitle dashboard-tile-text">organisations</p>
                </article>
              </div>
              <div class="tile is-parent">
                <article class="tile is-child box has-text-centered dashboard-tile">
                  <p class="title is-size-1 dashboard-tile-text">{$tiles.numContacts}</p>
                  <p class="subtitle dashboard-tile-text">contacts</p>
                </article>
              </div>
              <div class="tile is-parent">
                <article class="tile is-child box has-text-centered dashboard-tile">
                  <p class="title is-size-1 dashboard-tile-text">{$tiles.numOrganizationsWithoutCourses}</p>
                  <p class="subtitle dashboard-tile-text">organisations without courses</p>
                </article>
              </div>
              <div class="tile is-parent">
                <article class="tile is-child box has-text-centered dashboard-tile">
                  <p class="title is-size-1 dashboard-tile-text">{$tiles.numExpiringCourses}</p>
                  <p class="subtitle dashboard-tile-text">expiring courses (in 30 days)</p>
                </article>
              </div>
            </div>
          {/if}
        </div>

        <div class="dashboard-sub-horiz" style="height:250px;">
          <div class="box chart-box chart-padding">
            <h1 class="subtitle has-text-centered">Courses by country</h1>
            <Chart data={$numCoursesByCountry} type="donut" maxSlices="7" animate={false} height="180" />
          </div>

          <div class="box chart-box chart-padding">
            <h1 class="subtitle has-text-centered">Courses by topic</h1>
            <Chart data={$numCoursesByType} type="donut" maxSlices="7" animate={false} height="180" />
          </div>
        </div>

        <div class="dashboard-sub-horiz" style="padding-bottom:1rem;">
          <Table
            loading={$loading}
            fields={organizationExpiringCourseDtoFields}
            records={$expiringCourses}
            pagination={null}
            createEnabled={false}
            editEnabled={false}
            deleteEnabled={false}
            noElementsText="There are no expiring courses within 30 days"
          />
        </div>
      </div>
    </Container>
  </div>

  <div class="left-filter-box">
    <div class="left-filter-controls">
      <!-- svelte-ignore missing-declaration -->
      <Form>
        <div class="columns is-gapless">
          <div class="column is-4">
            <FormField>
              <Switch disabled={false} condensed={true} on:toggle={toggleAllCountries} value={$filter.allCountries} name="All Countries" labelSize="0.8rem" />
            </FormField>
          </div>

          {#if $filter.allCountries == 0}
            <div class="column is-8" style="margin-top: auto;">
              <FormField>
                <ClfDropdown name="" required={false} schema="COUNTRYXXX" small={true} bind:value={$filter.clfCountryId} />
              </FormField>
            </div>
          {/if}
        </div>

        <hr />

        <div class="field is-grouped">
          <div class="control search-buttons">
            <Button klass="is-primary" on:click={search}>
              <span class="icon" style="margin-right: 0.2rem;">
                <ImageAsset name="icon_search.png" />
              </span>
              Search
            </Button>

            <span style="margin: 0.5rem;" />

            <Button on:click={clear}>
              <span class="icon" style="margin-right: 0.2rem;">
                <ImageAsset name="icon_clear.png" />
              </span>
              Clear
            </Button>
          </div>
        </div>
      </Form>

      <a class="privacy-policy-link" href={PRIVACY_URL} target="_blank">Privacy Policy</a>
    </div>

    {#if $isUserLoggedIn && $isUserRoleADM}
      <div class="bottom-settings">
        <div class="valign-center-box">
          <ImageAsset name="icon_settings.png" />
          <a href="/settings">Settings</a>
        </div>
      </div>
    {/if}
  </div>
</Layout>

<style>
  .base {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-left: 300px;
    overflow-y: auto;
  }

  .dashboard-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
  }
  .dashboard-sub-horiz {
    display: flex;
    flex-direction: row;
    /*flex: 1;*/
    margin-bottom: 0.75rem;
  }
  .dashboard-tiles {
    /*margin-top: 1rem;*/
    padding: 0 0.75rem;
  }
  .dashboard-tile-text {
    color: #f8f8f8;
  }
  .dashboard-tile {
    background-color: #0672c1;
  }
  .chart-box {
    /*height: 320px;*/
    /*overflow: hidden;*/
    flex: 1;
    padding-top: 1rem;
    /*padding-right: 1rem;*/
    margin: 0;
    max-width: 100%;
  }
  /*
  .scrollable {
    overflow-y: auto !important;
  }
  */
  .chart-padding {
    margin-right: 0.75rem;
  }
</style>
