import { derived, get, writable } from 'svelte/store';
import { SearchService } from '../../services/search.service';
// import { ClassificationService } from '../../services/classification.service';
// import type { IDropdownItem } from '../../services/DTO/dropdown-item';
import { initDetailWithDetail } from "./map-detail.store";
import { navigate } from '../../app.logic';
import { clfCourseChannel, clfCourseLanguage, clfTechArea } from '../../classification.store';
export const loading = writable(false);
export const pois = writable([]);
export const detail = writable(null);
export const filter = writable();
export const allCountries = writable(1);
//export const clfTechAreaItems = writable<IDropdownItem[]>([]);
export const techAreaSelIds = derived(filter, $filter => !!$filter && !!$filter.criteria && !!$filter.criteria.techAreaIds ? $filter.criteria.techAreaIds : []);
export const techAreasSelectedItems = derived([clfTechArea, techAreaSelIds], ([$items, $ids]) => $items.filter(value => $ids.findIndex(id => id == value.oid) !== -1));
export const techAreasSelectableItems = derived([clfTechArea, techAreaSelIds], ([$items, $ids]) => $items.filter(value => $ids.findIndex(id => id == value.oid) == -1));
//export const clfCourseLanguageItems = writable<IDropdownItem[]>([]);
export const courseLanguageSelIds = derived(filter, $filter => !!$filter && !!$filter.criteria && !!$filter.criteria.languageIds ? $filter.criteria.languageIds : []);
export const courseLanguageSelectedItems = derived([clfCourseLanguage, courseLanguageSelIds], ([$items, $ids]) => $items.filter(value => $ids.findIndex(id => id == value.oid) !== -1));
export const courseLanguageSelectableItems = derived([clfCourseLanguage, courseLanguageSelIds], ([$items, $ids]) => $items.filter(value => $ids.findIndex(id => id == value.oid) == -1));
//export const clfTrainingChannelItems = writable<IDropdownItem[]>([]);
export const trainingChannelSelIds = derived(filter, $filter => !!$filter && !!$filter.criteria && !!$filter.criteria.channelIds ? $filter.criteria.channelIds : []);
export const trainingChannelSelectedItems = derived([clfCourseChannel, trainingChannelSelIds], ([$items, $ids]) => $items.filter(value => $ids.findIndex(id => id == value.oid) !== -1));
export const trainingChannelSelectableItems = derived([clfCourseChannel, trainingChannelSelIds], ([$items, $ids]) => $items.filter(value => $ids.findIndex(id => id == value.oid) == -1));
export async function initList() {
    // filter.set({ criteria: <ISearchCoursesRequestDTO>{}, params: null, sorting: "" });
    // pagination.set({ currentPage: 1, totalPages: 0, totalRecords: 0 });
    // allCountries.set(1);
    // await load();
    await clear();
}
export async function search() {
    await load();
}
export async function clear() {
    let criteria = {
        title: '',
        techAreaIds: [],
        channelIds: [],
        languageIds: [],
        countryId: undefined,
        startMonth: 0,
        startYear: 0,
        endMonth: 0,
        endYear: 0
    };
    filter.set({ criteria: criteria, params: null, sorting: "" });
    allCountries.set(1);
    await load();
}
export async function load() {
    loading.set(true);
    try {
        let _filter = get(filter);
        let _resp = await SearchService.searchCourses(_filter);
        pois.set(_resp.payload);
        // load clf data
        // await loadClfTechAreas();
        // await loadClfLanguages();
        // await loadClfChannels();
    }
    finally {
        loading.set(false);
    }
}
export function toggleAllCountries(evt) {
    let value = evt.detail;
    if (value == 1) {
        filter.set(Object.assign(Object.assign({}, get(filter)), { criteria: Object.assign(Object.assign({}, get(filter).criteria), { countryId: 0 }) }));
        allCountries.set(1);
    }
    else {
        allCountries.set(0);
    }
}
export async function onPoiClick(event) {
    let oid = event.detail;
    let _filter = get(filter);
    let _resp = await SearchService.searchDetail(oid, _filter);
    detail.set(_resp.payload);
}
export function onDetailClick(event) {
    initDetailWithDetail(event.detail);
    navigate("/map/detail");
}
// ----------------------------------------------------------------------
// Tags: Tech Areas
// ----------------------------------------------------------------------
// export async function loadClfTechAreas() {
//   let _resp = await ClassificationService.getAllValuesCombo("TECHAREAXX");
//   clfTechAreaItems.set(_resp.payload);
// }
export function onAddTechArea(evt) {
    let techAreaId = evt.detail;
    let _filter = get(filter);
    if (_filter.criteria.techAreaIds.findIndex(val => val == techAreaId) == -1)
        _filter.criteria.techAreaIds.push(techAreaId);
    filter.set(Object.assign({}, _filter));
}
export function onDelTechArea(evt) {
    let techAreaId = evt.detail;
    let _filter = get(filter);
    let idx = _filter.criteria.techAreaIds.findIndex(val => val == techAreaId);
    if (idx != -1)
        _filter.criteria.techAreaIds.splice(idx, 1);
    filter.set(Object.assign({}, _filter));
}
// ----------------------------------------------------------------------
// Tags: Course Language
// ----------------------------------------------------------------------
// export async function loadClfLanguages() {
//   let _resp = await ClassificationService.getAllValuesCombo("COURSELANG");
//   clfCourseLanguageItems.set(_resp.payload);
// }
export function onAddCourseLanguage(evt) {
    let languageId = evt.detail;
    let _filter = get(filter);
    if (_filter.criteria.languageIds.findIndex(val => val == languageId) == -1)
        _filter.criteria.languageIds.push(languageId);
    filter.set(Object.assign({}, _filter));
}
export function onDelCourseLanguage(evt) {
    let languageId = evt.detail;
    let _filter = get(filter);
    let idx = _filter.criteria.languageIds.findIndex(val => val == languageId);
    if (idx != -1)
        _filter.criteria.languageIds.splice(idx, 1);
    filter.set(Object.assign({}, _filter));
}
// ----------------------------------------------------------------------
// Tags: Training Channel
// ----------------------------------------------------------------------
// export async function loadClfChannels() {
//   let _resp = await ClassificationService.getAllValuesCombo("COURSETRCH");
//   clfTrainingChannelItems.set(_resp.payload);
// }
export function onAddTrainingChannel(evt) {
    let channelId = evt.detail;
    let _filter = get(filter);
    if (_filter.criteria.channelIds.findIndex(val => val == channelId) == -1)
        _filter.criteria.channelIds.push(channelId);
    filter.set(Object.assign({}, _filter));
}
export function onDelTrainingChannel(evt) {
    let channelId = evt.detail;
    let _filter = get(filter);
    let idx = _filter.criteria.channelIds.findIndex(val => val == channelId);
    if (idx != -1)
        _filter.criteria.channelIds.splice(idx, 1);
    filter.set(Object.assign({}, _filter));
}
